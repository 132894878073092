import { usePortals } from "react-portal-hook";

import { 
    ModalLogin,
    ModalVideo,
    ModalText,
    ModalAudio,
} from './templates'

export const useModals = () => {
    return usePortals();
}

export const resolveModal = (type) => {
    let field;

    const fields = {
        'login': ModalLogin, 
        'video': ModalVideo,
        'audio': ModalAudio,
        'text': ModalText
    }
    
    return fields[type]
}
