import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { Login } from '../../'

import { media } from '../../../styles/utils'

const ModalLogin = (props) => {

	return (
		<Wrapper>
			<Login
				closeModal={props.close}
			/>
		</Wrapper>
	)
}

// Layout

const Wrapper = styled.div`
	margin-bottom: 50px;

	${media.phone`
		margin-bottom: 16px;
	`}
`

ModalLogin.wrapper = css`

`

export default ModalLogin
