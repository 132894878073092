import React, {Component} from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import { media } from '../../styles/utils'
import { lightOrange, orange } from '../../styles/colors';
import { bgIcon } from '../../styles/global';

class Input extends Component {

    state = {
        focused: false
    }

    onBlur = () => {
        const { toggleFocus } = this.props;

        this.setState({ focused: false })

        if (toggleFocus) {
            toggleFocus(false)
        }
    }

    onFocus = () => {
        const { toggleFocus } = this.props;

        this.setState({ focused: true })

        if (toggleFocus) {
            toggleFocus(true)
        }
    }

    onChangeValue = (e) => {
        const { validator, onChangeValue } = this.props;
        const value = e.target.value;
        
        if (onChangeValue) {
            onChangeValue(value, validator)
        }
    }

    shouldShowError = () => {
        const { focused } = this.state;
        const { shouldValidate, isValid } = this.props;

        if (shouldValidate === true && !isValid) {
            return true
        }

        if (shouldValidate && !isValid && !focused) {
            return true
        }
    }

    render() {
        const { focused } = this.state;
        const { value, selectLabel, type, placeholder, className, errorMessage, helperText, disabled, name, locale, id, icon, component } = this.props;
        const showError = this.shouldShowError();
        const CustomComponent = component || false;

        return (
            <Wrapper
                data-name={id}
                data-locale={locale}
                error={showError}
                className={this.props?.existingUserHide && 'new-user-field'}
            >
                
                {selectLabel && (
                    <Label>
                        {selectLabel}
                    </Label>
                )}

                {!CustomComponent && (
                    <InputField 
                        className={className}

                        id={name}
                        type={type}
                        placeholder={placeholder}
                        value={value}
                        disabled={disabled}
                        hasIcon={icon}

                        onChange={!disabled && this.onChangeValue}
                        error={showError}
                
                        onFocus={this.onFocus} 
                        onBlur={this.onBlur}
                    />
                )}


                {CustomComponent && (
                    <CustomComponent
                        {...this.props}
                        onChange={!disabled && this.onChangeValue}
                    />
                )}

                <Icon
                    image={icon}
                />

                {showError && (
                    <Error>
                        {errorMessage}
                    </Error>
                )}

                {helperText && (
                    <HelperText>
                        {helperText}
                    </HelperText>
                )}
            </Wrapper>
        )
    }
}

export const red = '#CB0000';

export const inputStyle = css`
    appearance: none;
    box-shadow: none;
    display: flex;
    flex: 1;
    height: 40px;
    width: 100%;
    padding-left: 15px;
    box-sizing: border-box;

    border: 0;
    box-sizing: border-box;
    border: solid 1px orange;
    border-radius: 8px;

    &, &::placeholder {
        font-family: 'LatoWeb';
        font-size: 17px;
        color: ${lightOrange}
    }

    & {
        color: ${orange}
    }

    option {
        color: ${lightOrange} !important;
    }

    &:hover {
        border: solid 1px orange;
    }

    &:focus {
        border: solid 1px orange;
        box-shadow: 0px 0px 10px -1px ${lightOrange};
        outline: none;
    }

    ${props => {
        if (props.error) return css`
            & {
                color: ${red} !important;
            }

            &::placeholder {
                color: black;
            }

            &, &:focus {
                border: 1px solid ${red};
                box-shadow: 0px 0px 0px 1px ${red};
            }
        `
    }}

    ${props => {
        if (props.disabled) return css`
            pointer-events: none;
            
            &, &::placeholder {
                color: rgba(0, 0, 0, 0.2);
            }
        `
    }}

    ${props => {
        if (props.hasIcon) return css`
            padding-left: 43px;
            padding-bottom: 2px;
        `
    }}
`

export const Wrapper = styled.div`
    position: relative;
    width: 100%;

    ${props => {
        if (props.error) return css`
            select {
                &, &::placeholder {
                    color: black !important;
                }

                &, &:focus {
                    border: 1px solid ${red} !important;
                    box-shadow: 0px 0px 0px 1px ${red} !important;
                }
            }
        `
    }}
`


export const InputField = styled.input`
    ${inputStyle}
`

// Floating label

const Label = styled.div`
    font-family: 'LatoWeb';
    font-size: 15px;
    color: ${orange};
    margin-bottom: 16px;
`

// Helper Text

const message = css`
    line-height: 16px;
    font-size: 13px;
    letter-spacing: -0.01px;
`

export const HelperText = styled.div`
    ${message}
    margin-top: 8px;
    padding-left: 16px;
`

// Error

export const Error = styled.div`
    ${message}
    color: ${red};
    margin-top: 8px;
    padding-left: 16px;
`

// Icon

const Icon = styled.div`
    background-image: url(${props => props.image});
    width: 26px;
    height: 26px;
    ${bgIcon}
    opacity: 0.2;

    position: absolute;
    top: 7px;
    left: 8px;
`


export default Input;