import React, { useState, useEffect, useRef } from 'react';
import { useStaticQuery, Link, graphql } from 'gatsby'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import posed from 'react-pose'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import { updateSettings } from '../../store/settings'

import { Layout, Footer } from '../../components'
import { container, padding, bgIcon, hoverState } from '../../styles/global'
import { lightRed, orange } from '../../styles/colors'
import { isClient, media, useBreakpoint } from '../../styles/utils'
import { rgba } from 'polished';

import compassIcon from '../../assets/images/compass-icon.png'
import compassIconHover from '../../assets/images/compass-icon-hover.png'
import toggleOrange from '../../assets/images/accordion-toggle-orange.png'
import playIcon from '../../assets/images/play-button-orange.svg'
import pauseIcon from '../../assets/images/pause-button-orange.svg'
import { useMount } from 'react-use';

// data.text_box

const FloatingText = (props) => {
    const { data, audio, updateSettings, settings } = props
    const [textActive, toggleText] = useState(false);
    const [textExpanded, toggleTextExpanded] = useState(false)
    const isPhone = useBreakpoint('phone')
    const audioRef = useRef(false)

    useMount(() => {
        if (!isClient() || isPhone) return

        setTimeout(() => {
            toggleText(true)
        }, location.pathname == '/app/home' ? 6000 : 6000)
    })

	const muteAudio = () => {
		updateSettings({
			audio: null
		})
	}

    const playAudio = (audio) => {
		updateSettings({
			audio: audio
		})
	}

    useEffect(() => {
        if (audioRef?.current) {
            const audioFile = audioRef?.current?.props?.src
            const isPlaying = audioRef?.current?.isPlaying()

            if (isPlaying && settings.audio && settings.audio !== audioFile) {
                audioRef?.current?.audio?.current?.pause()
            }
        }
    }, [audioRef, settings])

    return (
		<Wrapper>
			<Toggle
				onClick={() => toggleText(!textActive)}
			>
                <Icon
                    image={compassIcon}
                />
			</Toggle>

			<TextBox
                active={textActive}
                textExpanded={textExpanded}
            > 
                <Close
                    onClick={() => toggleText(!textActive)}
                />

                <Top>
                    <Icon
                        image={compassIcon}
                    />
                    <Title>Compass</Title>
                </Top>

                {audio && (
                    <PlayAudio>
                        <Label>Play Audio</Label>
                            <AudioWrapper>
                                <AudioPlayer
                                    src={audio}
                                    ref={audioRef}
                                    layout={'horizontal'}
                                    showJumpControls={false}
                                    timeFormat={'(mm:ss)'}
                                    muted={true}
                                    onPlay={e => playAudio(audio)}
                                    onPause={e => {
                                        if (settings.audio == audio) {
                                            playAudio(false)
                                        }
                                    }}
                                />
                            </AudioWrapper>
                    </PlayAudio>
                )}

                <ReadMore
                    onClick={() => toggleTextExpanded(!textExpanded)}
                >
                    <Label>{`Read Text ........`}</Label>
                    <Icon
                        image={toggleOrange}
                    />
                </ReadMore>

                <ExpandedContent
                    className={'text-expander'}
                    pose={textExpanded ? 'visible' : 'hidden'}
                >
                    <Description
                        dangerouslySetInnerHTML={{
                            __html: data,
                        }}
                    />
                </ExpandedContent>
            </TextBox>
		</Wrapper>
    )
}


// Shared

const Description = styled.div``
const Icon = styled.div`
    background-image: url(${props => props.image});
    ${bgIcon};  
`

// Layout

const Wrapper = styled.div`
	position: absolute;
    top: 56%;
    right: 24px;
	z-index: 13;

    ${media.phone`
        position: relative;
    `}
`

// Audio Player

const AudioWrapper = styled.div`
    flex-grow: 1;

    .rhap_container {
        padding: 0;
        box-shadow: none;
        background-color: transparent;
        outline: none;
    }

    .rhap_additional-controls,
    .rhap_volume-controls {
        display: none;
    }

    .rhap_progress-section {
        .rhap_current-time,
        .rhap_progress-container {
            display: none;
        }

        .rhap_total-time::before {
            content: '(';
        }

        .rhap_total-time::after {
            content: ')';
        }
    }

    .rhap_horizontal .rhap_controls-section {
        margin: 0;
        flex: none;
    }

    .rhap_main-controls-button {
        margin: 0;
        color: ${orange};

        &, svg {
            width: 45px;
            height: 45px;
        }

        & {
            margin-right: -4px;
        }
    }
`

// Toggle

const Toggle = styled.div`
	cursor: pointer;

    ${Icon} {
        width: 52px;
        height: 52px;
        transition: 0.4s all ease;

        ${media.phone`
            width: 40px;
            height: 40px;
        `}
    }

    &:hover {
        ${Icon}{
            background-image: url(${compassIconHover});
        }
    }

    ${media.phone`
        position: fixed;
        bottom: 88px;
        right: 17px;
    `}
`

// Expandable text animation

export const ExpandedContent = posed.div({
    visible: { 
        opacity: 1,
        height: 'initial',
        flexBasis: '480px',
    },
    hidden: { 
        opacity: 0,
        height: 0,
        flexBasis: '0px',
    }
});


// Text Box

const Close = styled.div``
const Label = styled.div``
const PlayAudio = styled.div``
const ReadMore = styled.div``
const Top = styled.div``
const Title = styled.div``

const TextBox = styled.div`
	position: fixed;
    top: 33.33%;
	right: 24px;

	width: 386px;
    max-height: 66.66%;
	background: white;

	padding: 32px 28px 32px;
	box-sizing: border-box;

    flex-direction: column;

    ${props => {
        if (props.active) return css`
			display: flex;
        `
    }}

    ${Close} {
        background-image: url(${require('../../assets/images/compass-close.png')});
        ${bgIcon};
        width: 18px;
        height: 18px;

        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 12;
        cursor: pointer;
        ${hoverState};
    }

    ${Top} {
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        ${Icon} {
            width: 52px;
            height: 52px;
            box-sizing: border-box;

            ${media.phone`
                width: 40px;
                height: 40px;
            `}
        }

        ${Title} {
            text-transform: uppercase;
            font-size: 26px;
            letter-spacing: 0.5em;
            line-height: 48px;
            color: #444;
            margin-left: 15px;
        }
    }

    ${ReadMore}, ${PlayAudio} {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        user-select: none;

        ${Label}, .rhap_total-time {
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.3em;
            text-transform: uppercase;
            color: black;

            span {
                text-transform: initial;
            }
        }
    }

    ${ReadMore} {
        border-top: 1px solid #ddd;
        padding: 20px 0;
        cursor: pointer;

        ${Icon} {
            width: 37px;
            height: 19px;
            transform: none;

            ${props => {
                if (props.textExpanded)
                    return css`
                        transform: rotate(-180deg);
                    `
            }}
        }
    }

    ${PlayAudio} {
        padding-bottom: 14px;

        ${Label} {
            margin-right: 6px;
        }
    }

    // Expanded Text

    .text-expander {
        flex: 0 100%;
        overflow-y: auto;
    }

    ${Description} {
        font-family: 'LatoWeb';
        font-size: 15px;
        line-height: 23px;
        padding-right: 20px;

        > *:first-child {
            margin-top: 0;    
        }

		h2 {
            font-family: 'LatoWebLight';
			font-size: 24px;
			letter-spacing: 0.1em;
			line-height: 30px;
            text-transform: uppercase;
            color: #444;
		}

        p {
            color: #444;
        }

        a {
            color: #dfc0b0;
            border-bottom: 1px solid #dfc0b0;
            ${hoverState}
        }

        strong {
            font-family: 'LatoWebBold';
            font-size: 18px;
        }
    }

    ${media.phone`
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: none;
        width: 100vw;
        height: 100vh;
        max-height: initial;
        transition: opacity 0.45s ease;
    `}

	pointer-events: none;
    transition: all 2s ease;
	will-change: transform;
    opacity: 0;

    ${props => {
        if (props.active) return css`
			pointer-events: all;
            opacity: 1;
        `
    }}
`


const mapDispatchToProps = dispatch => ({
    updateSettings(setting) {
        dispatch(updateSettings(setting))
    },
})

const mapStateToProps = (state, props) => ({
    settings: state.settings.settings
})

export default connect(mapStateToProps, mapDispatchToProps)(FloatingText);