import { createGlobalStyle } from 'styled-components'

export const Fonts = createGlobalStyle`

    body {
        font-size: 14px;
        font-family: 'LatoWeb', Helvetica, sans-serif;
        -webkit-font-smoothing: antialiased;
    }

  

`