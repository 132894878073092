import React, { useState, useRef, useEffect} from 'react'
import { navigate, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { Location } from "@reach/router";
import { isAuthenticated } from "../../services/auth"
import ReactDOM from 'react-dom'
import { filter, findIndex, forEach, sum, sumBy, uniq } from 'lodash'
import OutsideClickHandler from 'react-outside-click-handler';
import { useMount, useUnmount, useSearchParam } from 'react-use';
import FullHeight from 'react-div-100vh'

import { isClient, media } from '../../styles/utils'
import { container, bgIcon, padding, hoverState } from '../../styles/global'
import { purple } from '../../styles/colors'
import Field from '../../modules/the-field';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import { logout } from '../../services/auth';
import { fetchData, postData } from '../../services/api';

const Header = (props) => {
	const [sections, setSections] = useState(false)
	const [pageLoaded, setPageLoaded] = useState(false)
	const [mobileNavActive, setMobileNavActive] = useState(false)
	const [modalActive, setModalActive] = useState(false)
	const [fieldGalleriesVisited, setFieldGalleriesVisited] = useState(false)
	const mobileNav = useRef()
	const resetLocking = useSearchParam('resetLocking');

	useMount(() => {
		document.addEventListener('touchstart', onTouchStartHandler)
		fetchSectionsVisited()
		fetchFieldGalleriesVisited()
	})

	useEffect(() => {
		if (resetLocking == 'true') {
			resetServerSections()
			delete window.localStorage.primingSectionsVisited
			delete window.localStorage.fieldGalleriesVisited
		}
	}, [resetLocking])

	useEffect(() => {
		if (!isClient()) return;

		window.enableSection = enableSection
		window.enableNextSection = enableNextSection
		window.visitedSubSection = visitedSubSection
		window.visitedFieldGallery = visitedFieldGallery

		if (sections?.length) {
			window.localStorage.sections = JSON.stringify(sections)
		}
	}, [sections])

	useEffect(() => {
		if (!isClient()) return;

		if (fieldGalleriesVisited) {
			window.localStorage.fieldGalleriesVisited = JSON.stringify(fieldGalleriesVisited)
		}

	}, [fieldGalleriesVisited])

	// The Field Gallery Unlocking

	const fetchFieldGalleriesVisited = (params) => {
		if (!isClient() || resetLocking == 'true') return;

		const galleriesVisited = window.localStorage.fieldGalleriesVisited;

		if (galleriesVisited) {
			setFieldGalleriesVisited(JSON.parse(galleriesVisited))
		} else {
			setFieldGalleriesVisited([])
		}
	}

	const visitedFieldGallery = (galleryType) => {
		if (!isClient()) return;
		
		let newGalleries = Object.assign([], fieldGalleriesVisited)

		newGalleries.push(galleryType)
		newGalleries = uniq(newGalleries)

		setFieldGalleriesVisited(newGalleries)

		if (newGalleries.length > 2) {
			enableNextSection('the-field')
		}
	}

	// Section Locking
	
	const fetchSectionsVisited = async () => {
		if (!isClient()) return;

		const userID = localStorage.userId;

		let sectionsVisited;

		await fetchData(`pda/sections-visited/${userID}`)
			.then((response) => {
				if (response) {
					sectionsVisited = JSON.parse(response)
				}
			})

		generateSections(sectionsVisited)
	}

	const enableSection = (slug) => {
		if (!slug || !sections) return;

		const sectionIndex = findIndex(sections, {slug: slug})

		if (sectionIndex) {
			let newSections = Object.assign([], sections)
			newSections[sectionIndex].inactive = false
			setSections(newSections)
			updateServerSections(newSections)
		}
	}

	const enableNextSection = (slug) => {
		if (!slug || !sections) return;

		const sectionIndex = findIndex(sections, {slug: slug})
		let newSections = Object.assign([], sections)

		if (sectionIndex && newSections[sectionIndex + 1]) {
			newSections[sectionIndex + 1].inactive = false
			setSections(newSections)
			updateServerSections(newSections)
		}
	}

	const visitedSubSection = (subSectionIndex, slug) => {
		if (!slug || !isClient()) return;

		const sectionIndex = findIndex(sections, {slug: slug})
		let newSections = Object.assign([], sections)

		if (sectionIndex && newSections[sectionIndex]) {
			const section = newSections[sectionIndex];
			const subSection = section?.subs?.[subSectionIndex];

			if (subSection) {
				subSection.visited = true
			}			
			
			const activeSubSections = filter(section.subs, {visited: true})

			if (activeSubSections?.length == section?.subs?.length) {
				newSections[sectionIndex + 1].inactive = false
			}

			setSections(newSections)
			updateServerSections(newSections)
		}
	}

	const updateServerSections = (sections) => {
		if (!isClient() || !sections) return;

		const userID = localStorage.userId;

		let savedSections = sections.map((section, i) => {
			let newSection = Object.assign({}, section);
			newSection.acf_json = null
		

			if (newSection?.subs?.length) {
				let newSubs = Object.assign([], newSection?.subs);

				newSubs = newSubs.map((sub, i) => {
					return {
						visited: sub?.visited || false
					}
				})

				newSection.subs = newSubs
			} else {
				newSection.subs = null
			}

			return newSection
		})

		postData('pda/sections-visited', {
			user_id: parseInt(userID),
			sections_visited: JSON.stringify(savedSections)
		})
	}

	const resetServerSections = () => {
		if (!isClient()) return;

		const userID = localStorage.userId;

		postData('pda/sections-visited', {
			user_id: parseInt(userID),
			sections_visited: false
		})
	}

	const generateSections = (sectionsVisited) => {
		let sections = props?.data?.allWordpressSections?.edges;
		let savedSections;

		if (sectionsVisited) savedSections = sectionsVisited

		sections = sections.map((section, i) => {
			let newSection = section.node
			newSection.inactive = true
			return newSection
		})

		let items = [
			{
				title: 'Home',
				slug: 'home',
				link: '/app/home',
				hero_colour: '#C2C1BF',
				fixed: true,
			},
			{
				title: 'The Priming',
				slug: 'the-priming',
				link: '/app/the-priming',
				hero_colour: `${purple}`,
				fixed: true,
			},
			{
				title: 'The Field',
				slug: 'the-field',
				link: '/app/the-field',
				hero_colour: '#757472',
				inactive: true,
				fixed: true,
			},
			...sections,
			{
				title: 'Afterward',
				slug: 'afterward',
				link: '/app/afterward',
				hero_colour: '#fff',
				fixed: true,
				inactive: true,
			},
		]

		// Check saved section data

		items = items.map((section, i) => {
			let newSection = section;

			if (savedSections?.[i]?.inactive == false) {
				newSection.inactive = false
			}

			if (newSection?.subs?.length) {
				let newSubs = Object.assign([], newSection?.subs);

				newSubs = newSubs.map((sub, subIndex) => {
					return {
						...sub,
						visited: savedSections?.[i]?.subs?.[subIndex]?.visited || false
					}
				})

				newSection.subs = newSubs
			} 

			return newSection
		})

		setSections(items)
	}

	useUnmount(() => {
		document.removeEventListener('touchstart', onTouchStartHandler);
	})

	useEffect(() => {
		document.body.style.position = modalActive ? 'fixed' : 'auto'
		document.body.style.pointerEvents = modalActive ? 'none' : 'auto'
	}, [mobileNavActive])

	const onTouchStartHandler = ({ target }) => {
		if (mobileNavActive) {
			const node = ReactDOM.findDOMNode(mobileNav.current)
			if (!node.contains(target)) {
				setMobileNavActive(false)
			}
		}
	}

	const renderNav = () => {
		if (!sections) return;

		const navItems = sections.map((item, i) => {
			const section = item;
			const sectionData = section.acf_json ? JSON.parse(section.acf_json) : null;
			const onAfterward = location.pathname.includes('afterward');

			return (
				<Location
					key={i}
				>
					{props => {
						return (
							<NavItem
								color={sectionData ? sectionData.hero_colour : section.hero_colour}
								active={props.location.pathname.includes(section.slug)}
								disabled={section.disabled}
								to={section.link || `/app/${section.slug}`}
								key={i}
								inactive={item.inactive}
							>
								<Title>
									{section.title}
								</Title>

								{i !== sections.length - 1 && (
									<Line/>
								)}
							</NavItem>
						)
					}}
				</Location>
			)
		})

		return (
			<Nav
				onClick={() => setMobileNavActive(true)}
			>
				{navItems}
			</Nav>
		)
	}

	const renderMobileSections = () => {
		if (!sections) return;

		const navItems = sections.map((item, i) => {
			return (
				<MobileNavItemComp
					item={item}
					i={i}
				/>
			)
		})

		return (
			<MobileSections>
				{navItems}
				<Divider/>

				<SecondaryLink
					to={'/info/about'}
				>
					About
				</SecondaryLink>

				<SecondaryLink
				    onClick={() => logout()}
					to={'/'}
				>
					Logout
				</SecondaryLink>

				<SecondaryLink
					to={'/info/terms-and-conditions'}
				>
					Terms & Conditions
				</SecondaryLink>

				<SecondaryLink
					to={'/info/privacy'}
				>
					Privacy
				</SecondaryLink>

			</MobileSections>
		)
	}

	const renderMobileNav = () => {
		return (
			<OutsideClickHandler
				onOutsideClick={() => mobileNavActive && setMobileNavActive(false)}
				useCapture={true}
			>
				<MobileNav
					active={mobileNavActive}
					ref={mobileNav}
				>
					<Close
						onClick={() => setMobileNavActive(false)}
					/>
					{renderMobileSections()}
				</MobileNav>
			</OutsideClickHandler>
		)
	}

	return (
		<Container>
			{isAuthenticated() && renderNav()}
			{isAuthenticated() && renderMobileNav()}
		</Container>
	)
}


const MobileNavItemComp = (props) => {
	const { item, i } = props;
	const section = item.node ? item.node : item;
	const sectionData = section.acf_json ? JSON.parse(section.acf_json) : null;
	const [active, setActive] = useState(false)

	return (
		<MobileNavItem
			color={sectionData ? sectionData.hero_colour : section.hero_colour}
			solid={item.slug == 'home'}
			disabled={section.disabled}
			inactive={item.inactive}
			key={i}
		>
			<Circle
				onClick={() => navigate(section.link || `/app/${section.slug}`)}
			/>

			<Title
				onClick={() => setActive(!active)}
			>
				{section.title}
			</Title>

			{section.subs && (
				<>
					<Arrow
						active={active}
						onClick={() => setActive(!active)}
					/>
					<SubPages
						active={active}
					>
						{section.subs.map((sub) => {
							return (
								<Title
									onClick={() => navigate(`/app/${section.slug}/${sub.slug}`)}
								>
									{sub.title}
								</Title>
							)
						})}
					</SubPages>
				</>
			)}
		</MobileNavItem>
	)
}


const Container = styled.div`
	${container}
	${padding}
`

// Shared

const Title = styled.div``

// Nav

const Nav = styled.div`
	position: absolute;
	top: 24px;
	right: 38px;

	display: flex;
	flex-direction: column;
	z-index: 10;

	${media.phone`
		top: 0;
		right: 0;
		padding-right: 10px;
		padding-top: 16px;
		width: 40px;
		align-items: flex-end;
	`}
`

const NavItem = styled(Link)`
	width: 19px;
	height: 19px;
	border-radius: 50%;
	border: 2px solid ${props => props.color};
	margin-bottom: 16px;
	position: relative;
	cursor: pointer;
	transition: background 0.25s ease;
	user-select: none;

	${Title} {
		color: #999999;
		font-family: 'LatoWeb';
		font-size: 12px;
		letter-spacing: 0.1em;
		line-height: 13px;
		text-transform: uppercase;
		text-align: right;

		position: absolute;
		right: 0;
		padding-right: 27px;
		top: 0;
		height: 30px;

		display: flex;
		align-items: center;

		transform: translateY(-6px);

		opacity: 0;
		transition: opacity 0.25s ease;
	}

	${media.phone`
		margin-bottom: 4px;
		width: 12px;
		height: 12px;
		border-width: 1px;
		pointer-events: none;

		${Title} {
			display: none;
		}
	`}

	&:hover {
		background: ${props => props.color};

		${Title} {
			opacity: 1;
		}
	}

	${props => {
		if (props.active) return css`
			background: ${props => props.color};

			${Title} {
				opacity: 1;
			}
		`
	}}

	${props => {
		if (props.disabled) return css`
			pointer-events: none;
		`
	}}

	/* Active */

	${props => {
		if (props.inactive == true) return css`
			pointer-events: none;
			opacity: 0.5;
		`
	}}
`

const Line = styled.div`
	position: absolute;
	bottom: -16px;
	height: 16px;
	left: 50%;
	transform: translateX(calc(50% - 2.5px));
	background-image: linear-gradient(180deg, #000, #000 50%, transparent 75%, transparent 100%);
	background-size: 2px 4px;
	background-position: 0px 10px;
    background-repeat: repeat-y;
	width: 2px;
	opacity: 0.1;

	${media.phone`
		display: none;
	`}
`

// Mobile Nav

const MobileNav = styled(FullHeight)`
	width: 100%;
	max-width: 280px;
	background: #3f3f44;
	z-index: 20;
	right: 0;
	position: fixed;

	df

	padding: 18px 24px;
	box-sizing: border-box;
	transform: translateX(100%);
	transition: transform 0.35s ease;
	display: none;

	overflow: scroll;

	${media.phone`
		display: block;
	`}

	${props => {
		if (props.active) return css`
			transform: translateX(0);
		`
	}}
`

const Close = styled.div`
	width: 44px;
	height: 64px;
	${bgIcon}
	background-image: url(${require('../../assets/images/mobile-nav-close.svg')});
`

const MobileSections = styled.div`
	display: flex;
	flex-direction: column;
`

const Circle = styled.div``

const Arrow = styled.div`
	${props => {
		if (props.active) return css`
			transform: rotate(180deg)
		`
	}}
`

const MobileNavItem = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;

	&:not(:last-child) {
		margin-bottom: 20px;
	}

	${Circle} {
		width: 19px;
		height: 19px;
		border-radius: 50%;
		border: 1px solid ${props => props.color};
		margin-bottom: 8px;
		position: relative;
		cursor: pointer;
		transition: background 0.25s ease;
		user-select: none;
	}

	> ${Title} {
		font-size: 14px;
		line-height: 22px;
		letter-spacing: 0.3em;
		text-transform: uppercase;
		color: white;
		font-weight: bold;
		max-width: 165px;
		text-align: center;
		transform: translateX(2px);
		position: relative;
		user-select: none;
	}

	${Arrow} {
		position: absolute;
		top: 55px;
		right: 0;

		background-image: url(${require('../../assets/images/mobile-nav-arrow.png')});
		${bgIcon}
		width: 13px;
		height: 15px;
	}

	${props => {
		if (props.solid) return css`
			${Circle} {
				background: ${props => props.color};
			}
		`
	}}

	/* Active */

	${props => {
		if (props.inactive == true) return css`
			pointer-events: none;
			opacity: 0.5;
		`
	}}
`

const SubPages = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 12px;

	${Title} {
		font-size: 13px;
		line-height: 32px;
		letter-spacing: 0.3em;
		color: #bbbbbb;
		text-transform: uppercase;
		font-weight: bold;
	}

	display: none;

	${props => {
		if (props.active) return css`
			display: flex;
		`
	}}
`

const Divider = styled.div`
	width: 100%;
	height: 1px;
	background: #6b6b6f;
	margin-top: 10px;
`

const SecondaryLink = styled(Link)`
	font-size: 11px;
	letter-spacing: 0.2em;
	margin-top: 29px;
	color: rgba(255,255,255, 0.5);
	text-transform: uppercase;
	font-weight: bold;
	text-align: center;
`

export default Header
