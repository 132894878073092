import React, {Component} from 'react'
import styled from 'styled-components';
import { lightOrange, orange } from '../../styles/colors';
import { media } from '../../styles/utils'

class InputCheckbox extends Component {

    defaultProps = {
        style: null
    }

    state = {
        checked: this.props.value || false
    }

    handleChange = () => {
        const { checked } = this.state;

        this.setState({
            checked: !checked
        })

        this.props.onChangeValue(!checked);
    }

    render() {
        const { label, value } = this.props;

        return (
            <Wrapper
                style={this.props.style}
                onClick={this.handleChange}
            >

                <Input 
                    name="isGoing"
                    type="checkbox"
                    checked={value}
                />

                <BlankLabel
                    type="checkbox_label"
                />

                <Label>
                    {label}
                </Label>

            </Wrapper>
        )
    }
}

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    position: relative;

    font-family: 'LatoWeb';
    font-size: 17px;
    color: ${lightOrange};
    z-index: 2;
`

const Label = styled.div`
    display: flex;
    padding-left: 12px;
    user-select: none;
    font-family: 'LatoWeb';
    font-size: 17px;
    color: ${orange};
    transform: translateY(-1px);
`

const BlankLabel = styled.label`
    height: 0px;
    width: 0px;
    z-index: 1;
`

const Input = styled.input`
    appearance: none;
    height: 20px;
    width: 20px;
    border: solid 1px orange;
    border-radius: 4px;
    pointer-events: none;

    & + label:after {
        position: absolute;
        left: 7px;
        top: 1px;
        z-index: 100;
        content: '';
        font-size: 19px;
        color: ${orange};
    }

    &:checked + label:after {
        content: '✓'    
    }
`



export default InputCheckbox;