import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { hoverState } from '../../../styles/global'

import { media } from '../../../styles/utils'

const ModalText = (props) => {
	return (
		<Wrapper>
			{props.text && (
				<>
					<Text
						dangerouslySetInnerHTML={{__html: props.text}}
					/>
				</>
			)}
		</Wrapper>
	)
}

// Layout

const Wrapper = styled.div`
	padding: 0 30px;

	${media.phone`
		padding: 0 10px;
	`}
`

const Text = styled.div`
	&, p {
		font-size: 16px;
		line-height: 24px;
		color: #444444;
	}

	a {
		color: #dfc0b0;
		border-bottom: 1px solid #dfc0b0;
		${hoverState}
	}
`

ModalText.wrapper = css`

`

export default ModalText
