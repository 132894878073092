import React, { Component } from 'react'
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { findDOMNode } from 'react-dom'
import { connect } from 'react-redux'
import { Link } from 'gatsby'

import { media } from '../../styles/utils'
import { container, innerContainer, padding, hoverState, bgImage, bgIcon, input} from '../../styles/global'
import { orange, grey, blue } from '../../styles/colors';

import { Input } from '../';
import { fields } from './data';
import { getIntitalState, checkValidation } from './utils';
import { handleLogin } from '../../services/auth';

import { getSettings, fetchSettings, updateSettings } from '../../store/settings';
import { navigate } from '@reach/router';

class Login extends Component {

    state = {
        fields: null,
    }

    componentDidMount = () => {
        // Generate initial state for field data
          
		this.setState({
			fields: getIntitalState(fields)
        })
    }
    
    handleFieldChange = (key, value, validator) => {
       // console.log(key, value, validator)
        this.setState({
            fields: {
				...this.state.fields,
				[key]: {
					value: value,
					valid: validator && validator(value)
				}
			}
        })
    }
    
    handleSubmit = () => {
        const { updateSettings } = this.props;
        const { fields } = this.state;
        const self = this;

        const cred = {
            username: fields.username.value,
            password: fields.password.value
        }

        //console.log(cred)

        // updateSettings({
        //     isAuthenticated: true
        // })
        // localStorage.setItem('auth', true);
        // navigate('/app/home')

        handleLogin(cred)
            .then(response => {  
                if (response.error) {
                    self.setState({
                        validationError: response.message
                    })

                } else {
                    updateSettings({
                        isAuthenticated: true
                    })
                    
                    this.props.closeModal?.()

                    console.log(this.props.closeModal)

                    navigate('/app/home')
                }
            })
    }

    renderFields = () => {
        return fields.map((field, i) => {
            return (
                <Input
                    key={i}
                    name={field.name}
                    type={field.type}
                    placeholder={field.placeholder}
                    onChangeValue={(value) => this.handleFieldChange(field.name, value, field.validator)}
                    validator={field.validator}
                    icon={field.icon}
                />
            )
        })
    }
    
    render() {
        const { fields, validationError } = this.state;

        return (
            <Wrapper>
                <Form>
                    {this.renderFields()}

                    <Forgot
                        href={'http://admin.adventuresinthefield.com/wp-login.php?action=lostpassword'}
                    >
                        Forgot password?
                    </Forgot>

                    <Button
                        onClick={this.handleSubmit}
                    >
                        Login
                    </Button>

                    {validationError && (
                        <ValidationError>
                            {validationError}
                        </ValidationError>
                    )}
                </Form>
            </Wrapper>
        )
    }
}


// Shared

const Title = styled.div``

const Button = styled.div`
    ${input}
    background: ${orange};

    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'LatoWebBold';
    font-size: 19px;
    letter-spacing: 1.9px;
    border-radius: 8px;
    margin-top: 14px;
    
    color: white;
    text-transform: uppercase;
    /* ${hoverState} */
    transition: background 0.25s ease;

    


`

// Wrapper

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    > ${Title} {
        font-size: 28.5px;
        font-weight: bold;
        line-height: 36px;
        letter-spacing: 0.7px;
        color: ${orange};
    }
`


// Login Form

const Form = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    > * {
        margin-bottom: 8px;
    }

    ${Button} {
        background: ${orange};
        &:hover{
            background: #e15631;
            cursor: pointer;
        }
    }
`

const ValidationError = styled.div`
    color: red;
    margin-top: 16px;
    text-align: center;
`

const Forgot = styled.a`
    font-family: 'LatoWebBoldItalic';
    font-size: 12px;
    color: ${orange};
    font-weight: bold;
    align-self: flex-start;
    ${hoverState}
`

// Register

const Register = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;

    ${Title} {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 23.5px;
        letter-spacing: 4.2px;
        color: ${blue};
        margin-top: auto;
    }

    ${Button} {
        background: ${blue};
        height: 52px;
        margin-top: 8px;
        &:hover{
            background-color:#2d8b7e;
            cursor: pointer;
        }
    }
`

const mapDispatchToProps = dispatch => ({
    fetchSettings() {
        dispatch(fetchSettings())
    },
    updateSettings(setting) {
        dispatch(updateSettings(setting))
    },
})

const mapStateToProps = (state, props) => ({
    settings: state.settings.settings
})

export default connect(mapStateToProps, mapDispatchToProps)(Login);