import React from 'react'
import { navigate, Link } from 'gatsby'
import styled, { css } from 'styled-components'

import { media, tracking } from '../../styles/utils'
import { grey } from '../../styles/colors'
import { container, bgIcon, padding, hoverState } from '../../styles/global'
import { logout, isAuthenticated } from '../../services/auth';
import { Fade } from '../../transitions';


class Footer extends React.Component {

	state = {

    }
    
	render() { 
        const { copyrightOnly } = this.props;
    
		return (
            <Fade
                order={3}
            >
                <Wrapper
                    color={this.props.color}
                    isAuthenticated={isAuthenticated()}
                >
                    <Container>

                        <Item  
                            underline={false}
                        >
                        © AITF {new Date().getFullYear()}
                        </Item> 

                        {!copyrightOnly && (
                            <>
                             <Item>|</Item>

                                <ItemLink
                                    to={'/info/about'}
                                >
                                    About
                                </ItemLink>

                                <Item>|</Item>

                                <ItemLink
                                    to={'/info/terms-and-conditions'}
                                >
                                    Terms & Conditions
                                </ItemLink>

                                <Item>|</Item>

                                <ItemLink
                                    to={'/info/privacy'}
                                >
                                    Privacy
                                </ItemLink>

                                { isAuthenticated() && (
                                    <>
                                    <Item>|</Item>        
                                    <ItemLink
                                        onClick={() => logout()}
                                        to={'/'}
                                    >
                                        Logout
                                    </ItemLink>
                                    </>
                                )}    
                            </>
                        )}
                    </Container>	
                </Wrapper>
            </Fade>
		)
	}
}

const Wrapper = styled.div`
    /* position: fixed; */
    transform: translateY(-28px);
    width: 100%;
    height: 0;

    ${props => {
		if (props.color) return css`
			${Item} {
                color: ${props.color};
            }

            ${ItemLink} {
                color: ${props.color};
            }
		`
	}}

    ${props => {
        if (props.isAuthenticated) return css`
            ${media.phone`
                display: none;
            `}
        `
    }}
`

const Container = styled.div`
	${container}
	${padding}
    margin: 0 auto;
    display:flex;
    max-width: 500px;
    justify-content: space-around;
`

const Item = styled.div`
    font-size: 11px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: ${grey};

    ${media.phone`
        display: none;

        &:first-child {
            display: flex;
        }
    `}

    ${props => {
		if (props.underline) return css`
			border-bottom: 1px solid ${grey};
		`
	}}
    
`
const ItemLink = styled(Link)`
    color: ${grey};
    font-size: 11px;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    &:hover {
        text-decoration: underline;
    }
`


export default Footer
