import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { VideoPlayer } from '../../'

import { media } from '../../../styles/utils'

const ModalVideo = (props) => {
	// console.log(props)
	return (
		<Wrapper>
			{props.video && (
				<VideoPlayer
					video_type='embed'
					video_embed_src={props.video}
				/>
			)}
		</Wrapper>
	)
}

// Layout

const Wrapper = styled.div`
	max-width: 545px;
	width: 100%;
`

ModalVideo.wrapper = css`

`

export default ModalVideo
