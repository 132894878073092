import { required } from "../Input/utils";

export const fields = [
    {
        type: 'text',
        name: 'username',
        placeholder: 'Username or Email',
        validator: required,
        icon: require('../../assets/images/user-icon.png')
    },
    {
        type: 'password',
        name: 'password',
        placeholder: 'Password',
        validator: required,
        icon: require('../../assets/images/lock-icon.png')
    },
]