import posed from 'react-pose';

export const Fade = posed.div({
    enter: { 
		opacity: 1,
		y: 0,
		pointerEvents: 'all',
        delay: ({order, delay}) => delay || order * 1000,
        transition: { 
			duration: 700
		}
    },
    exit: { 
        opacity: 0,
		delay: 0,
		// pointerEvents: 'none',
        transition: { 
			duration: 700 
		}
    }
});