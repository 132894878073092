import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import posed, { PoseGroup } from "react-pose";
import Helmet from 'react-helmet'

import { GlobalStyles } from '../../styles/global';
import { Fonts } from '../../styles/fonts';

import { isAuthenticated } from "../../services/auth"

import '../../styles/global'
import '../../styles/fonts'
import Header from '../Header/Header';
import FloatingText from '../FloatingText/FloatingText';
import { Location } from '@reach/router';
import { Fade } from '../../transitions';

const Layout = ({ children, textBox, textBoxAudio, delay, ...props }) => (
  	<StaticQuery
    	query={graphql`
      		query SiteTitleQuery {
				site {
					siteMetadata {
						title
					}
        		}
				wordpressField	 {
					acf_json	
					sections {
						title
						slug
						acf_json
					}
				}
				
				allWordpressSections {
					edges {
						node {
							title
							slug
							acf_json
							subs {
								title
								slug
								acf_json
							}
						}
					}
				}
      		}
    	`}
    	render={data => {
			return (
				<>	
                    <Helmet>
                        <meta name="facebook-domain-verification" content="xhmfi78we4cleao5croaq4v9lvvr1w" />
                    </Helmet>

					{isAuthenticated() && (
						<Header
							data={data}
						/>
					)}
					
					<GlobalStyles/>
					<Fonts/>

					{textBox && (
						<Fade
							order={delay ? 4 : 2}
						>
							<FloatingText
								data={textBox}
								audio={textBoxAudio}
							/>
						</Fade>
					)}		

					{children}        
				</>
			)
		}}
  	/>
)



export default Layout
