import React, { Component } from 'react'
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import ReactPlayer from 'react-player'
import { Slider, Direction } from 'react-player-controls'
import screenfull from 'screenfull'
import { findDOMNode } from 'react-dom'

import { media } from '../../styles/utils'
import { container, innerContainer, padding, hoverState, bgImage, bgIcon} from '../../styles/global'

import playIcon from '../../assets/images/play.svg'
import pauseIcon from '../../assets/images/pause.svg'
import fullscreenIcon from '../../assets/images/fullscreen.svg'

class VideoPlayer extends Component {

    state = {
        showCoverImage: true,
        url: null,
        playing: false,
        played: 0,
        lastIntent: 0,
        loaded: 0,
        duration: 0,
        seeking: false,
        muted: false,
        controls: false,
        loop: false,
        volume: 0.8,
        ratio: 56.25,  
        videoHover: false
    }

    componentWillMount = () => {
        const { video_type, video_custom_mp4, video_embed_src, video_ratio, autoplay } = this.props;        

        if (video_type=='embed') {

            this.setState({ 
                url: video_embed_src,
                controls: true,
            })
        } 

        if (video_type=='custom') {
            this.setState({ 
                url: video_custom_mp4.url,
                ratio: video_ratio,
                muted: true,
                loop: true
            })
            if(autoplay) this.setState({ playing: true, showCoverImage: false })
        }
    }

    onClickCoverImagePlay = () => {
        this.setState({ 
            playing: true,
            showCoverImage: false,
            //controls: true,
            muted: false
        })
    }
    togglePlayPause = () => {
        this.setState({ playing: !this.state.playing })
    }
    setPlayPos = () => {
        this.setState({ playing: this.state.lastIntent })
    }

    onProgress = state => {
        //console.log('onProgress', state)
        // We only want to update time slider if we are not currently seeking
        if (!this.state.seeking) {
            this.setState(state)
        }
    }

    onDuration = (duration) => {
        //console.log('onDuration', duration)
        this.setState({ duration })
    }

    // onSeekMouseDown = e => {
    //     this.setState({ seeking: true })
    // }
    // onSeekChange = e => {
    //     this.setState({ played: parseFloat(e.target.value) })
    // }
    // onSeekMouseUp = e => {
    //     this.setState({ seeking: false })
    //     this.player.seekTo(parseFloat(e.target.value))
    // }

    onClickFullscreen = () => {
        screenfull.request(findDOMNode(this.player))
    }

    formatTime = (seconds) => {
        const date = new Date(seconds * 1000)
        const hh = date.getUTCHours()
        const mm = date.getUTCMinutes()
        const ss = this.pad(date.getUTCSeconds())
        if (hh) {
          return `${hh}:${pad(mm)}:${ss}`
        }
        return `${mm}:${ss}`
    }
      
    pad = (string) => {
        return ('0' + string).slice(-2)
    }

    ref = player => {
        this.player = player
    }
        
    render() {  

        const { video_type, video_custom_mp4, video_embed_src, cover_image, video_ratio } = this.props;

        return (

            <React.Fragment>

                <VideoWrapper
                    className={'video-player'}
                    ratio={this.state.ratio}
                    onMouseEnter={()=>this.setState({videoHover: true})}
                    onMouseLeave={()=>this.setState({videoHover: false})}
                >
           
                    <Video 
                        ref={this.ref}
                        url={this.state.url}
                        controls={this.state.controls}
                        muted={this.state.muted}
                        playing={this.state.playing}
                        loop={this.state.loop}
                        width={'100%'}
                        height={'100%'}
                        onProgress={this.onProgress}
                        onDuration={this.onDuration}
                        height={this.props.height}
                        // onReady={() => console.log('player:', this.player)}
                    />

                     { video_type=='custom' && this.state.showCoverImage && video_custom_mp4.url && (
                        <PlayIconContainer
                            onClick={() => this.onClickCoverImagePlay()}
                        >
                            <PlayIcon />
                        </PlayIconContainer>
                    )}
                
                    { video_type=='custom' && this.state.showCoverImage  && video_custom_mp4.url && cover_image.sizes && ( 
                        <CoverImage
                            image={cover_image.sizes.large}
                        />
                    )}
                    
                    {  video_type=='custom' && !this.state.showCoverImage && video_custom_mp4.url && (
                        <Controls
                            active={this.state.videoHover}
                        >

                            <PlayPause
                                 onClick={()=>this.togglePlayPause()}
                            >
                                {!this.state.playing && ( 
                                    <PlayIcon/> 
                                )}
                                {this.state.playing && ( 
                                    <PauseIcon/> 
                                )}
                            </PlayPause>
                            

                            <TimeElapsed>{this.formatTime(this.state.duration * this.state.played)}</TimeElapsed>
                  

                            <SeekBar
                                onChange={newValue => this.setState(() => ({ played: newValue }))}
                                //onChangeStart={startValue => this.setState(() => ({ lastValueStart: startValue }))}
                                // onChangeEnd={endValue => this.setState(() => ({ lastValueEnd: endValue }))}
                                // onIntent={intent => this.setState(() => ({ lastIntent: intent }))}
                            >
                                <SliderHandle 
                                    value={this.state.played * 100} 
                                />
                                <SliderBar 
                                    value={this.state.played * 100} 
                                />
                                <SliderBarIntent 
                                    value={this.state.lastIntent * 100} 
                                />
                                
                            </SeekBar>


                            <TimeRemaining>-{this.formatTime(this.state.duration * (1 - this.state.played))}</TimeRemaining>

                            <FullScreenIcon
                                onClick={this.onClickFullscreen}
                            />

                        </Controls>
                    )}

                </VideoWrapper>

            </React.Fragment>   
            
        )
    }
}





const VideoWrapper = styled.div`
    position: relative;
    padding-top: ${props => props.ratio}%;
`

const PlayIcon = styled.div`
    background-image: url(${playIcon});
	${bgIcon}
    width: 12px;
    height: 14px;
`
const PauseIcon = styled.div`
    background-image: url(${pauseIcon});
	${bgIcon}
    width: 12px;
    height: 14px;
`
const PlayPause = styled.div`
    &:hover {
        cursor: pointer;
    }
`

const PlayIconContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 6;
    display: flex;
    align-items: center;
    justify-content: center;  
    &:hover {
        cursor: pointer;
    }
    ${PlayIcon}{
        width: 48px;
        height: 56px;
    }
`




const CoverImage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    background-image: url(${props => props.image});
	${bgImage}
    width: 100%;
    height: 100%;
`
const Controls = styled.div`
    position: absolute;
    max-width: 90%;
    top: 0;
    left: 0;
    bottom: 20px;
    right: 0;
    z-index: 7;
    display: flex;
    align-items: flex-end;
    justify-content: space-around; 
    margin: 0 auto;
    opacity: 0;
    transition: all 0.75s ease;

    ${props => {
		if (props.active) return css`
			opacity: 1;
		`
	}}

`

const SeekBar = styled(Slider)`
    position: relative;
    width: 60%;
    height: 2px;
    background: rgba(255,255,255,0.25);
    top: -5px;
`

const SliderBar = styled.div`
    position: absolute;
    background: white;
    top: 0;
    bottom: 0;
    left: 0;
    height: 2px;
    width: ${props => props.value}%;
`

const SliderBarIntent = styled.div`
    ${SliderBar}
    background: rgba(255,255,255,0.5);
`

const SliderHandle = styled.div`
    position: absolute;
    width: 10px;
    height: 10px;
    background: white;
    border-radius: 100%;
    top: 0;
    left: ${props => props.value}%;
    margin-top: -4px;
    margin-left: -2px;

    /* transform: scale(1);
    transition: transform 0.2s;
    &:hover: {
        transform: scale(1.3);
    } */
`

const timeDisplay = css`
    font-size: 12px;
    color: white;
    letter-spacing: 0;
    line-height: 12px;
`

const TimeElapsed = styled.div`
    ${timeDisplay};
`
const TimeRemaining = styled.div`
    ${timeDisplay};
`

const FullScreenIcon = styled.div`
    background-image: url(${fullscreenIcon});
	${bgIcon}
    width: 18px;
    height: 14px;
    &:hover{
        cursor: pointer;
    }
`

const Video = styled(ReactPlayer)`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

	video {
		object-fit: cover;
	}
`


export default VideoPlayer;