import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import { updateSettings } from '../../../store/settings'

import { media } from '../../../styles/utils'
import { orange } from '../../../styles/colors';
import { useUnmount } from 'react-use';

const ModalAudio = (props) => {

	const muteAudio = () => {
		props.updateSettings && props.updateSettings({
			audio: null
		})
	}
	
	useUnmount(() => {
		if (props.originalAudio && props.updateSettings) {
			props.updateSettings({
				audio: props.originalAudio
			})
		}
	})

	return (
		<Wrapper>
			{props.audio && (
				<AudioPlayer
					src={props.audio.url}
					showJumpControls={false}
					onPlay={e => muteAudio()}
				/>
			)}
		</Wrapper>
	)
}

// Layout

const Wrapper = styled.div`
	width: 100%;
	position: relative;

	.rhap_play-pause-button {
		position: absolute;
		top: 0;
		left: 0;
	}

	.rhap_volume-container {
		position: absolute;
		top: 6px;
		right: 0;
		width: 100px;

		${media.phone`
			width: 64px;
			right: 10px;
		`}
	}

	.rhap_progress-section {
		margin-left: 40px;
		margin-right: 100px;

		${media.phone`
			margin-right: 84px;
		`}
	}

	.rhap_container {
		box-shadow: none;
		outline: none;
	}

	[class^="rhap"] {
		outline: 0 !important;
	}

	.rhap_main-controls-button,
	.rhap_volume-button {
		color: #444444;
	}
	
	.rhap_additional-controls {
		display: none;
	}

	/* Time */

	.rhap_time {
		font-size: 14px;
		line-height: 20px;
		color: #444444;
	}

	/* Progress bar */

	.rhap_progress-indicator,
	.rhap_volume-indicator {
		background: ${orange};
		box-shadow: none;
		width: 16px;
		height: 16px;
		top: -6px;
	}

	.rhap_progress-filled {
		background: ${orange};
	}
`

ModalAudio.wrapper = css`

`

export default ModalAudio
