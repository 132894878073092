import React, { useState, useEffect, createContext } from 'react'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'
import OutsideClickHandler from 'react-outside-click-handler';
import { useMount, useUnmount } from 'react-use';
import { resolveModal } from './utils';
import { orange } from '../../styles/colors';
import { bgImage, hoverState } from '../../styles/global';
import { media, isClient } from '../../styles/utils';

export const modalContext = createContext();

const Modal = (props) => {
	const { modal_layout, modal_title, ...rest } = props;
	const ModalLayout = resolveModal(modal_layout);

	useMount(() => {
		document.body.style.overflow = 'hidden'
	})

	useUnmount(() => {
		document.body.style.overflow = 'auto'
	})
	
	
	if (props.trackOpen && isClient() && window.fbq) {
		window.fbq('track', 'ViewContent', {
			modalType: modal_layout,
		});
	}

	return (
		<Wrapper>
			<OutsideClickHandler
				onOutsideClick={rest.close}
			>
				{ModalLayout && (
					<ModalWrapper>
						{modal_title && (
							<Title>
								{modal_title}
							</Title>
						)}

						<Close
							onClick={rest.close}
						/>

						<ModalLayout
							{...rest}
						/>
					</ModalWrapper>
				)}
			</OutsideClickHandler>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	top: 0;
	height: 100vh;
	background: rgba(0,0,0, 0.4);
	z-index: 4;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.card {
		min-width: 500px;
	}
`

const Title = styled.div``
const Close = styled.div``

const ModalWrapper = styled.div`
	background: white;
	padding: 58px 42px 42px;

	display: flex;
	flex-direction: column;
	align-items: center;
	width: 630px;
	box-sizing: border-box;
	position: relative;
	max-height: 80vh;
	overflow-y: scroll;

	${media.phone`
		padding: 58px 24px 24px;
		width: calc(100vw - 25px)
	`}

	${Title} {
		font-family: 'LatoWebLight';
		font-size: 26px;
		line-height: 34px;
		color: ${orange};
		letter-spacing: 0.3em;
		margin-bottom: 50px;
		text-transform: uppercase;
		text-align: center;

		${media.phone`
			margin-bottom: 32px;
		`}
	}

	${Close} {
		background-image: url(${require('../../assets/images/close-icon-32px-black.svg')});
		${bgImage};
		width: 18px;
		height: 18px;

		position: absolute;
		top: 15px;
		right: 15px;
		z-index: 12;
		cursor: pointer;
	}
`

export default Modal
