import { forEach } from 'lodash'

export const checkValidation = (stateFields) => {        
    let fieldsValid = true;
    
    forEach(stateFields, (item) => {
        console.log(item.valid)
        if (!item.valid) {
            fieldsValid = false
        }
    })

    return fieldsValid
}	

export const getIntitalState = (data) => {
    let fields = {}

    forEach(data, (field) => {
        fields[field.name] = {
            name: field.name,
            value: null,
            valid: false
        }
    })

    return fields;
}