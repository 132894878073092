import React, { Component } from 'react'
import Helmet from 'react-helmet'

class MetaTags extends Component {
	
    render() {
        const { title, description, image, url } = this.props;
		
        return (
           
            <Helmet
                title={title}
                meta={[
                    { name: 'description', content: description},
                    { property: 'og:url', content: url },
                    { property: 'og:image', content: image },
                    { property: 'og:title', content: title },
                    { property: 'og:description', content: description },
                    { property: 'twitter:card', content: 'summary' }, 
                    { property: 'twitter:title', content: title },
                    { property: 'twitter:description', content: description}
                ]}
            />

        )
    }
}

export default MetaTags