import React, { useState, useEffect } from 'react'
import { useStaticQuery, Link, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import { Fade } from '../transitions.js'
import { connect } from 'react-redux'

import { updateSettings } from '../store/settings';

import { Layout, Footer, MetaTags } from '../components'
import { container, padding, bgImage, bgIcon, hoverState } from '../styles/global'
import { lightRed, darkGrey, green, lightBlue, blue } from '../styles/colors'

import triadRings from '../assets/images/triad-rings-Field-pg.png'
import logo from '../assets/images/logo-watermark.png'
import circleLines from '../assets/images/field-circle-lines.png'
import arrowIcon from '../assets/images/orange-button.png'
import arrowIconHover from '../assets/images/orange-button-hover-active.png'
import PrivateRoute from '../components/PrivateRoute';
import { isClient, media } from '../styles/utils';
import { getBackground } from '../utils.js';
import { useMount } from 'react-use'

const getData = () => {
    const staticQuery = useStaticQuery(query);
    return {
        data: JSON.parse(staticQuery.wordpressField.acf_json),
        sections: staticQuery.wordpressField.sections
    }
}

const Field = ({updateSettings}) => {
    const { data, sections } = getData();
    const [mounted, toggleMount] = useState(false);

    useMount(() => {
        if (data.audio_file) {
            updateSettings({
                audio: data.audio_file.url
            })
        }
    })

    if (!mounted) {
        setTimeout(() => {
            toggleMount(true)
        }, 4000);
    }

    return (
        <Layout
            textBox={data.text_content}
            delay={data.background_image_initial}
            textBoxAudio={data?.text_content_audio}
        >

            <MetaTags 
                title={`Adventures in the Field`}
            />
     
            <Wrapper
                bgImage={getBackground(data, mounted)}
            >
                <Fade order={4}>
                    <Logo 
                        to={'/app/home'}
                    />           
                </Fade>

                <Container>

                    <Fade
                        order={3}
                        style={{
                            zIndex: 2
                        }}
                    >
                        <Title>The Field</Title>

                        <SubTitleLink
                            to={'/app/field-suite'}
                        >
                            <Text
                                dangerouslySetInnerHTML={{__html: `Your quest<br />starts here`}}
                            />
                            <ArrowIcon/> 
                        </SubTitleLink>
                    </Fade>

            
                    <Fade order={3}>
                        <CircleLines/>
            
                        <Triad>
                        
                            <Rings/>
                            { 
                                sections.map((section, i) => {    
                                    const sectionData = JSON.parse(section.acf_json); 
                                    return (
                                        <SubLink
                                            key={i}
                                            index={i}
                                            //to={`/app/${section.slug}`}
                                            color={sectionData.hero_colour}
                                        >
                                            <Text>{section.title}</Text>
                                        </SubLink>
                                    )
                                })
                            }
                        </Triad>
                    </Fade>
                    
                </Container>
            </Wrapper>

            <Footer 
                color={'white'}
            />
        </Layout>
    )
}


const Wrapper = styled.div`
    ${bgImage}
    background-image: url(${props => props.bgImage});
    height: 100vh;
    overflow: hidden;
    transition: background 2s ease;
`

const Container = styled.div`
	${container};
    ${padding};
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`


const Title = styled.div`
    font-family: 'LatoWebLight';
    text-transform: uppercase;
    font-size: 36px;
    letter-spacing: 0.3em;
    color: #4d4d4d;
    text-align: center;
    margin-bottom: 20px;
    margin-left: 48px;
    margin-top: -70px;

    ${media.phone`
        font-size: 28px;
        line-height: 36px;
        margin-left: 0;
        margin-bottom: 10px;
    `}
`


const Text = styled.div``
const ArrowIcon = styled.div``
const SubTitleLink = styled(Link)`
    z-index: 2;
    display: flex;
    margin-bottom: 20px;  
    margin-left: 48px;
    font-size: 14px;
    letter-spacing: 0.3em;
    line-height: 19px;
    text-transform: uppercase;
    text-align: right;
    font-family: 'LatoWebBold';

    &:hover {
        ${ArrowIcon}{
            transition: 0.4s all ease;
            background-image: url(${arrowIconHover});
        }
    }
    
    ${Text} {
       margin-right: 10px;
       color: ${darkGrey};
    }

    ${ArrowIcon}{
        background-image: url(${arrowIcon});
        ${bgIcon}
        width: 36px;
        height: 36px;
        margin-top: 2px;
        &:hover {
            transition: 0.4s all ease;
            background-image: url(${arrowIconHover});
        }
    }


    ${media.phone`
        font-size: 12px;
        line-height: 16px;

        ${ArrowIcon} {
            margin-top: 0px;
        }
    `}
    
`

const Logo = styled(Link)`
    position: absolute;
    top: 24px;
    left: 24px;
    ${bgImage}
    background-image: url(${logo});
    width: 107px;
    height: 86px;
    
    ${media.tablet`
        width: 88px;
        height: 71px;
        top: 5px;
        left: 5px;
    `}
`

const Triad = styled.div`
    position: relative;
    width: 438px;
    height: 408px;

    ${media.tablet`
        width:  330px;
        height: 300px;
    `}
`

const CircleLines = styled.div`
    position: absolute;
    
    ${bgIcon}
    background-image: url(${circleLines});
    width: 781px;
    height: 673px;

    top: 50%;
    left: calc(50% + 77px);
    transform: translate(-50%, -50%);      
`


const Rings = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    ${bgIcon}
    background-image: url(${triadRings});
    width: 100%;
    height: 100%;
`

// const SubLink = styled(Link)`
const SubLink = styled.div`
    position: absolute;
    margin: 0 40px;
    width: 160px;
    height: 240px;

    ${Text} {
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 0.3em;
        text-align: center;
        color: ${darkGrey};
        position: relative;
        top: 50%;
        transform: translateY(-50%);     
        font-family: 'LatoWebBold';
        /* ${hoverState}; */
        color: ${props => props.color};
    }

    /* 0 The Orientation - top right, 1 The Conversation – bottom right, 2 The Resonation – mid left */

    ${props => {
        if (props.index==0) return css`
            top: 4px;
            left: 169px;
            ${media.tablet`
                left: 100px;
            `}
        `
        if (props.index==1) return css`
            top: 168px;
            left: 193px;
            ${media.tablet`
                top: 125px;
                left: 120px;
            `}
        `
        if (props.index==2) return css`
            top: 87px;
            left: 0px;
            ${media.tablet`
                top: 65px;
                left: -25px;
            `}  
		`
    }}

    ${media.tablet`
        width: 160px;
        height: 170px;

        ${Text} {
            font-size: 11px;
        }
    `}
    
`

const query = graphql`
	query {
		wordpressField	 {
			acf_json	
            sections {
                title
                slug
                acf_json
            }
		}
	}
`

const mapDispatchToProps = dispatch => ({
    updateSettings(setting) {
        dispatch(updateSettings(setting))
    },
})

export default connect(null, mapDispatchToProps)(Field);