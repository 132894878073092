import React, {Component} from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import { media } from '../../styles/utils'
import { lightOrange, orange } from '../../styles/colors';
import { bgIcon, bgImage } from '../../styles/global'

const Select = (props) => {
    const { value, options, onChange, validator } = props;

    const renderOptions = () => {
        if (!options) return;

        return options.map((option, i) => {
            return (
                <option 
                    key={i}
                    value={option.value}
                    disabled={option.disabled}
                >
                    {option.label}
                </option>
            )
        })
    }

    return (
        <Wrapper
            className={'select'}
        >
            <SelectInput
                value={value}
                defaultValue={props.defaultValue}
                onChange={(event) => onChange(event.target.value)}
            >
                {renderOptions()}
            </SelectInput>
        </Wrapper>
    )
}


export const Wrapper = styled.div`
    position: relative;
    width: 100%;
    margin-left: auto;
    user-select: none;
`

const SelectInput = styled.select`

    &, &::placeholder {
        font-family: 'LatoWeb';
        font-size: 17px;
        color: ${lightOrange} !important;
    }

    ${props => {
        if (props.value && props.value !== props.defaultValue) return css`
            &, &::placeholder {
                color: ${orange} !important;
            }
        `
    }}
`

export default Select;